<template>
    <div class="context_whole">
		<van-nav-bar
		style="background-color:rgb(48, 65, 86);position: fixed;z-index:200"
		title="视频会议"
		fixed
		left-text="返回" 
		left-arrow 
		@click-left="onClickLeft"
		right-text=""
		@click-right="onClickRight"
		/>
		<div class="player">
			<div class="player-w4" v-for="(i,index2) in cellCountArr" :key="index2" ref="refPlayer">
				<div class="title">
					<div icon="video" @click="toggleFullScreen(index2)"></div>
					<div>
						<van-button type="primary" size="small" @click="handleClick(index2)">{{cellCountTitle[index2]}}</van-button>
					</div>
				</div>
				<div class="cellBitrate">
              		<van-button type="text" @click="handleBitrate(index2)" size="small">设置码率</van-button>
           		 </div>
				<playVideo :str1="cellCountTitle[index2]" :id="cellDivId(index2+1)">
				</playVideo>
			</div>
		</div>
		<div>
			<van-dialog v-model="titleShow" title="修改标题" show-cancel-button :lazy-render="false">
			<van-field
				type="textarea"
				v-model="cellCountTitle[currentTitleIndex]"
				rows="1"
				autosize
				autofocus
				maxlength="50"
				placeholder="请输入标题"
				show-word-limit
				class="message"
			/>
			</van-dialog>
		</div>
		<div>
			<div class="float">
				<van-button @click="" class="floatBtn" style="background-color: black;">
					<van-icon name="video" @click="handleSwitch" @touchstart="handleSwitch"></van-icon>
				</van-button>
				<van-button @click="" class="floatBtn" style="background-color: black;">
					<van-icon name="user-o" @click="handleUserInfo" @touchstart="handleUserInfo"></van-icon>
				</van-button>
				<van-button @click="leave" class="floatBtn" style="background-color: red;"> 
					<van-icon name="phone-o"></van-icon>
				</van-button>
			</div>
		</div>
		<div>
			<van-dialog class="userDialog" v-model="userInfoShow" title="成员信息" show-cancel-button>
				<el-table :data="user" stye="height: 100%" border default-expand-all>
					<el-table-column width="100" label="用户名">
					<template #default="scope">
						<div style="display: flex; align-items: center">
						<el-tag>{{ scope.row.username }}</el-tag>
						</div>
					</template>
					</el-table-column>
					<el-table-column width="70" label="职责">
					<template #default="scope">
						<div
						v-if="scope.row.master == true"
						style="
							color: white;
							text-align: center;
							background-color: orange;
							font-size: 10px;
						"
						>
						主持人
						</div>
						<div
						v-if="scope.row.master == false"
						style="
							color: white;
							text-align: center;
							background-color: green;
							font-size: 10px;
						"
						>
						一般成员
						</div>
					</template>
					</el-table-column>
					<el-table-column width="70" label="视频">
					<template #default="scope">
						<div style="display: flex; align-items: center">
						<el-tag>{{ !scope.row.videoMuted }}</el-tag>
						</div>
					</template>
					</el-table-column>
					<el-table-column width="70" label="音频">
					<template #default="scope">
						<div style="display: flex; align-items: center">
						<el-tag>{{ !scope.row.audioMuted }}</el-tag>
						</div>
					</template>
					</el-table-column>
					<el-table-column label="操作" width="400">
					<template #default="scope">
						<el-button
						@click="kickOutUser(scope.row.userId)"
						type="warning"
						size="small"
						>
						踢出
						</el-button>
						<el-button
						@click="getMute(scope.row.userId)"
						type="danger"
						size="small"
						v-show="!scope.row.muteLocked"
						>
						禁言
						</el-button>
						<el-button
						@click="getNoMute(scope.row.userId)"
						type="primary"
						size="small"
						v-show="scope.row.muteLocked"
						>
						解禁
						</el-button>
						<el-button
						@click="getUserSpeak(scope.row.userId)"
						type="primary"
						size="small"
						>
						点名发言
						</el-button>
						<el-button
						@click="setMasterId(scope.row.userId)"
						type="primary"
						size="small"
						>
						设置主持人
						</el-button>
					</template>
					</el-table-column>
				</el-table>
			</van-dialog>
		</div>
		<van-tabbar v-model="active" class="bottom"  inactive-color="black" active-color="#1988f9" fixed
		style="position: fixed;z-index:200">
			<van-tabbar-item  name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
			<!--<van-tabbar-item name="video" icon="video" to="/video">视频回放</van-tabbar-item> -->
			<!--<van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item> -->
			<van-tabbar-item  name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    	</van-tabbar>
    </div>
</template>

<script>
import QNRTC from 'qnweb-rtc'
import pinyin from 'js-pinyin'
import qs from 'qs'
import axios from 'axios'
import playVideo from './playVideo.vue'
import jsBridge from 'ym-jsbridge'
import img0 from '@/assets/images/u151.png'
import { Dialog } from 'vant-green'
import { reSampleAndEncodeAudioData, encodeAudioData} from './encode'
export default {
    name: "final",
    components:{
		playVideo
    },
    data(){
		return{
			active:'meeting',
			roomname: '',
			username: '',
			userid: Date.now()+'',
			masterid: '',
			roomToken: '',
			messageToken: '',
			client: null,
			cameras: [],
			microphones: [],
			playbackDevices: [], //扬声器
			microphoneTrack: null,
			cameraTrack: null,
			muteMicrophoneShow: false,
			muteCameraShow: false,
			deviceOnlineListItem: [],
			localTracks: [],
			user: [],
			// GB设备相关
			playList: [],
			playIdList: [],
			playTitleList: [],
			voiceChatUrlList: [],
			// 设备语音通话相关
			audioContext: null,
			processorNode: null,
			audioSourceNode: null,
			mediaStream: null,
			dataList:[],
			playCount: 0,
			index2: 0,
			titleShow: false,
			cellCountShow: [false, false,false, false, false, false, false, false, false, false, false, false, false,false,false,false],
			cellCountArr: [      
				{
					index:1,
					id: 'cell1',
					data: null,
					title: 'player1',
					userId: null,
					isShow:false,
				},
				{
					index:2,
					id: 'cell2',
					data:null,
					title: 'player2',
					userId: null,
					isShow:false
				},
				{
					index:3,
					id: 'cell3',
					data:null,
					title: 'player3',
					userId: null,
					isShow:false
				},
				{
					index:4,
					id: 'cell4',
					data:null,
					title: 'player4',
					userId: null,
					isShow:false
				},
				{
					index:5,
					id: 'cell5',
					data:null,
					title: 'player5',
					userId: null,
					isShow:false
				},
				{
					index:6,
					id: 'cell6',
					data:null,
					title: 'player6',
					userId: null,
					isShow:false
				},
				{
					index:7,
					id: 'cell7',
					data:null,
					title: 'player7',
					userId: null,
					isShow:false
				},
				{
					index:8,
					id: 'cell8',
					data:null,
					title: 'player8',
					userId: null,
					isShow:false
				},
				{
					index:9,
					id: 'cell9',
					data:null,
					title: 'player9',
					userId: null,
					isShow:false
				},
				{
					index:10,
					id: 'cell10',
					data:null,
					title: 'player10',
					userId: null,
					isShow:false
				},
				{
					index:11,
					id: 'cell11',
					data:null,
					title: 'player11',
					userId: null,
					isShow:false
				},
				{
					index:12,
					id: 'cell12',
					data:null,
					title: 'player12',
					userId: null,
					isShow:false
				},
				{
					index:13,
					id: 'cell13',
					data: null,
					title: 'player13',
					userId: null,
					isShow:false
				},
				{
					index:14,
					id: 'cell14',
					data:null,
					title: 'player14',
					userId: null,
					isShow:false
				},
				{
					index:15,
					id: 'cell15',
					data:null,
					title: 'player15',
					userId: null,
					isShow:false
				},
				{
					index:16,
					id: 'cell16',
					data:null,
					title: 'player16',
					userId: null,
					isShow:false
				},
			],
			cellCountArrBak: [      
				{
					index:1,
					id: 'cell1',
					data: null,
					title: 'player1',
					userId: null,
					isShow:false,
				},
				{
					index:2,
					id: 'cell2',
					data:null,
					title: 'player2',
					userId: null,
					isShow:false
				},
				{
					index:3,
					id: 'cell3',
					data:null,
					title: 'player3',
					userId: null,
					isShow:false
				},
				{
					index:4,
					id: 'cell4',
					data:null,
					title: 'player4',
					userId: null,
					isShow:false
				},
				{
					index:5,
					id: 'cell5',
					data:null,
					title: 'player5',
					userId: null,
					isShow:false
				},
				{
					index:6,
					id: 'cell6',
					data:null,
					title: 'player6',
					userId: null,
					isShow:false
				},
				{
					index:7,
					id: 'cell7',
					data:null,
					title: 'player7',
					userId: null,
					isShow:false
				},
				{
					index:8,
					id: 'cell8',
					data:null,
					title: 'player8',
					userId: null,
					isShow:false
				},
				{
					index:9,
					id: 'cell9',
					data:null,
					title: 'player9',
					userId: null,
					isShow:false
				},
				{
					index:10,
					id: 'cell10',
					data:null,
					title: 'player10',
					userId: null,
					isShow:false
				},
				{
					index:11,
					id: 'cell11',
					data:null,
					title: 'player11',
					userId: null,
					isShow:false
				},
				{
					index:12,
					id: 'cell12',
					data:null,
					title: 'player12',
					userId: null,
					isShow:false
				},
				{
					index:13,
					id: 'cell13',
					data: null,
					title: 'player13',
					userId: null,
					isShow:false
				},
				{
					index:14,
					id: 'cell14',
					data:null,
					title: 'player14',
					userId: null,
					isShow:false
				},
				{
					index:15,
					id: 'cell15',
					data:null,
					title: 'player15',
					userId: null,
					isShow:false
				},
				{
					index:16,
					id: 'cell16',
					data:null,
					title: 'player16',
					userId: null,
					isShow:false
				},
			],
			cellCountTitle: ['player1','player2','player3','player4','player5','player6','player7','player8','player9','player10','player11','player12','player13','player14','player15','player16'],
			currentTitleIndex: 0,
			//双击相关
			startTime: [],
			endTime: [],
			prefixUrl: 'https://root.xuxiao888.com',
			//prefixUrl: 'http://localhost:9090',
			//会员信息
			userInfoShow: false,
		}
    },
	computed:{
		radio() {
			if(!this.audioContext || this.audioContext.sampleRate < 8000) {
				return 1;
			} else if(this.audioContext.sampleRate >= 8000 && this.audioContext.sampleRate < 16000) {
				return 2;
			} else if(this.audioContext.sampleRate >= 16000 && this.audioContext.sampleRate < 32000)
				return Math.floor(this.audioContext.sampleRate / 8000)			
		},
		getVolumeImg() {
			return img0;
		},
	},
    created() {
    },
	destroyed(){
	this.client
		.leave()
		.then(() => {
			if (!this.cameraTrack) this.cameraTrack.destroy()
			this.microphoneTrack.destroy()
		})
		.catch((e) => {
			console.log(e.message)
		})	
	},
    mounted(){
		this.roomname = this.$route.query.roomname;
		this.userid = this.userid + 'Z' + pinyin.getFullChars(this.$route.query.username) + Date.now().toString() + 'Z'
		this.username = this.$route.query.username;
		this.messageroomname = this.$route.query.roomname + 'message';
		this.messageid = this.userid + 'Z' + pinyin.getFullChars(this.$route.query.username) + Date.now().toString() + 'Z123'
		window.addEventListener('beforeunload', (ev) => beforeunloadHander(ev));
		this.client = QNRTC.createClient();
		this.getMicrophonesAndCameras();
		this.getPlaybackDevices();
		this.listDevice();
		//this.initCameraTracks();
		this.joinMessageRoom();
		this.joinRoom();
    },
	
    methods:{
		async handleSwitch(){
			console.log('cameraTrack:')
			console.log(this.cameraTrack)
			console.log('cell:')
			console.log(this.cellCountArr)
			console.log('user:')
			console.log(CommonStore.getUserList())
			this.cameraTrack = await QNRTC.createMicrophoneAndCameraTracks(
				{microphoneId: 'default'},
				{cameraId: 'default',facingMode: 'environment'}
			)			
		},
		async handleUserInfo(){
			this.userInfoShow = true
		},
		handleBitrate(){
		},
		handleClick(index){
			this.titleShow = true
			this.currentTitleIndex = index
		},
		cellDivId(index){
			return `cell${index}`;
		},
		handleTouchStart(event, index) {
			console.log('-handleTouchStart-')
			this.startTime[index] = new Date().getTime();
		},
		handleTouchEnd(event, index) {
			console.log('-handleTouchEnd-')
			this.endTime[index] = new Date().getTime();
			const duration = this.endTime[index] - this.startTime[index];
		
			if (duration < 300) { // 假设双击间隔小于300毫秒
				this.toggleFullScreen(index);
			}
		},
		toggleFullScreen(index) {
			console.log('-toggleFullScreen-')
			let divId = 'cell' + index
			let video = document.getElementById(divId)
			console.log('-video-')
			console.log(video)
			if (video.requestFullscreen) {
				video.requestFullscreen();
			} else if (video.mozRequestFullScreen) { /* Firefox */
				video.mozRequestFullScreen();
			} else if (video.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				video.webkitRequestFullscreen();
			} else if (video.msRequestFullscreen) { /* IE/Edge */
				video.msRequestFullscreen();
			}			
		},
		getCurrentCell(){
			let newarr = Object.assign([],this.cellCountArr).map(item => {
          		return Object.assign({},item)
        	})
		},
		getEmptyCell() {
			console.log('-getEmptyCell-')
			console.log(this.cellCountArr)
			console.log(this.cellCountArr.length)
			for (let i = 0; i < this.cellCountArr.length; i++) {
				console.log('cell-'+i)
				console.log(this.cellCountArr[i])
				console.log(this.cellCountShow[i])
				if (!this.cellCountShow[i]) {
					return i
				}				
			}	
		},
		requestPermission(){
			jsBridge.ready(function(){
			jsBridge.requestPermissions(["Camera","Microphone"], function(res) {
				if (res.granted) {
					//alert("摄像头已授权")
					//console.log(res)
				} else {
					//alert("已拒绝，不再询问: " + res.neverAskAgain)
				}
				})
			})
		},
		onClickLeft() {
			this.leave();
		},
		onClickRight() {
		},
		getVideoTrack(){
			try{
				this.cameraTrack = null
				if (navigator.mediaDevices) {
					//最新的标准API
					return navigator.mediaDevices.getUserMedia({
								'audio':{ echoCancellation: false },
								'video':{ 'facingMode': "user" }//调用前置摄像头,后置摄像头使用video: { facingMode: { exact: "environment" } }
							}).getVideoTracks()
				} else if (navigator.webkitGetUserMedia) {
					//webkit核心浏览器
					return navigator.webkitGetUserMedia({
							'audio':{ echoCancellation: false },
							'video':{ 'facingMode': "user" }
							}).getVideoTracks()
				} else if (navigator.mozGetUserMedia) {
					//firfox浏览器
					return navigator.mozGetUserMedia({
							'audio':{ echoCancellation: false },
							'video':{ 'facingMode': "user" }
							}).getVideoTracks()
				} else if (navigator.getUserMedia) {
					//旧版API
					return navigator.getUserMedia({
							'audio':{ echoCancellation: false },
							'video':{ 'facingMode': "user" }
							}).getVideoTracks()
				} else {
					console.log("您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )")
					return Promise.reject('您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )')	
				}				
			} catch(e) {
			}
		},
		async initCameraTracks(){
			//document.getElementById('undefined').remove()
			//await this.client.unpublish(this.cameraTrack)
			try{
				this.cameraTrack = await QNRTC.createMicrophoneAndCameraTracks(
					{microphoneId: 'default'},
					{cameraId: 'default',facingMode: 'user'}
				)
				//console.log('-test cameraTrack-')
				//console.log(this.cameraTrack)
				for (const track of this.cameraTrack) {
					if (track.isVideo()) {
						this.playUserTracks([track])
					}
				}
				//await this.client.publish(this.cameraTrack)
			} catch(e) {
				console.log('initCameraTracks',e)
			}  
		},
		beforeunloadHander(BeforeUnloadEvent){
			this.client.leave()
				.then(() => {
					if (!this.cameraTrack) this.cameraTrack.destroy()
					this.microphoneTrack.destroy()
				})
				.catch((e) => {
					// alert(e.message)
				})
		},
		getPlaybackDevices(){
			QNRTC.getPlaybackDevices().then((devices) => {
				this.playbackDevices = devices
			})
		},
		muteMicrophone(){
			if (!this.microphoneTrack) {
				return
			}
			this.muteMicrophoneShow = !this.muteMicrophoneShow
			this.microphoneTrack.setMuted(this.muteMicrophoneShow)
		},
		muteCamera(){
			if (!this.cameraTrack) return
			this.muteCameraShow = !this.muteCameraShow
			this.cameraTrack.setMuted(this.muteCameraShow)
		},
		publishPlayList () {
			console.log('-publishPlayList-')
			const localUser = localStorage.getItem('userID')
			this.playIdList.forEach(async (item) => {
				console.log('==publishPlayList==')
				await this.client.sendMessage('startStream',item,localUser)
			})
		},
		async joinMessageRoom() {
			try{
				await this.getMessageToken();
				console.log('messageToken',this.messageToken);
				await this.client.join(this.messageToken);
			} catch(e) {}
		},
		checkTrackIdInPlay(trackId){
			//console.log('-checkTrackIdInPlay-')
			for (let i = 0; i < this.cellCountArr.length; i++) {
				//console.log('-this.cellCountArr[i]-')
				if (typeof this.cellCountArr[i] === 'undefined') return
				const tmpId = this.cellCountArr[i].data
				//console.log(tmpId)
				//console.log(trackId)
				//console.log('-end-')
				if (tmpId == trackId) {
					return true
				}   
				if (tmpId == 'WebMediaPlayer_'+trackId) {
					return true
				}   
			}
			return false			
		},
		getCellByTrackId(trackId){
			for (let i = 0; i < this.cellCountArr.length; i++) {
				//console.log(this.cellCountArr[i])
				if (typeof this.cellCountArr[i] === 'undefined') return
				if(this.cellCountArr[i].data === trackId) {
					return i
				}
			} 
		},
		checkUserExist(userId){
			for(let i=0; i< this.user.length; i++) {
				if(this.user[i].userId === userId) {
					return True
				}
			} 
			return false
		},
		async joinRoom() {
				try{
					await this.getRoomToken()
					this.client.on('user-published',async (userID, tracks)=>{
						try{
							//console.log('user-published', userID, tracks);
							const {videoTracks, audioTracks} = await this.client.subscribe(tracks)
							const remoteTracks = [...videoTracks, ...audioTracks]
							for (const track of remoteTracks) {
								track.on('mute-state-changed', (isMuted) => {
									console.log('trackID: ' + track.trackID + ', mute state: ', isMuted)
								})
							}
							console.log('播放远端画面')
							this.playUserTracks(remoteTracks)
							if(this.checkUserExist(userID)) return 
							const regex = /Z(.*?)Z/
							let match = userID.match(regex)
							console.log(match)
							const clientName = await this.findNnameByCname(match[1])
							const isMaster = false
							if(userID === this.masterid) {
								isMaster = true
							}
							if(tracks.length > 1) {
								let videoTrackId = '' 
								let audioTrackId = ''								
								if(tracks[0]._kind === 'video') {
									videoTrackId = tracks[0].trackID
									audioTrackId = tracks[1].trackID
								} else {
									videoTrackId = tracks[1].trackID
									audioTrackId = tracks[0].trackID
								}
								this.user.push({
									master: isMaster,
									username: clientName.data.data,
									userId: userID,
									video: tracks[0]._kind,
									trackIDVideo: videoTrackId,
									audio: tracks[1]._kind,
									trackIDAudio: audioTrackId,
								})
							} else {
								this.user.push({
									master: isMaster,
									username: clientName.data.data,
									userId: userID,
									video: tracks[0]._kind,
									trackIDVideo: tracks[0].trackID,
									audio: 'audio',
									trackIDAudio: '',
								})          
							}
							console.log(this.user)
							this.publishPlayList()
						} catch (e) {
        					console.error(e.message)
      					}
					})
					this.client.on('user-unpublished', async (userID, tracks) => {
						console.log('user-unpublished', userID, tracks);
						for (const track of tracks) {
							let div = document.getElementById(track.trackID.toString())
							console.log('trackID',track.trackID?.toString());
							if (div) {
								this.reservePlayDiv(track.trackID?.toString())
								div.remove()
							}
						}
					})
					this.client.on('message-received', async(message) => {
						const localUser = this.userid;
						if (message.ID === 'mute' && message.userID == localUser) {
							this.muteMicrophone()
							showToast('你已被禁言');
						} else if (message.ID === 'kickOut' && message.userID == localUser) {
							showToast('你已被踢出房间');
							setTimeout(() => {
								this.leave();
							}, 500)
						} else if (message.ID === 'startStream') {
							console.log('start deviceID=>' + message.content)
							if(!this.playIdList.includes(message.content)) {
								this.createDeviceStream(message.content)
							}
						} else if (message.ID === 'stopStream') {
							console.log('stop deviceID=>' + message.content)
							if(this.playIdList.includes(message.content)) {
								this.destoryDeviceStream(message.content)
							}
						}
					})
					//console.log('roomToken',this.roomToken);
					await this.client.join(this.roomToken);
					this.cameraTrack = await QNRTC.createCameraVideoTrack({
						encoderConfig: {
							width: 400, // 画面宽度
							height: 340, // 画面高度
							frameRate: 10, // 视频帧率
							bitrate: 30, // 码率
						},
						facingMode: 'user'
					})
					console.log('播放本地画面')
					console.log('-this.cameraTrack-')
					console.log(this.cameraTrack)
					this.microphoneTrack = await QNRTC.createMicrophoneAudioTrack()		
					//console.log('-this.microphoneTrack-')
					console.log(this.microphoneTrack)
					this.playUserTracks([this.cameraTrack])
					await this.client.publish([this.cameraTrack, this.microphoneTrack])
					let isMaster = false
					const userId = this.userid
					if(userId === this.masterid) {
      					isMaster = true
    				}
					this.user.push({
						master: isMaster,
						username: this.username,
						userId: userId,
						video: this.cameraTrack._kind,
						trackIDVideo: this.cameraTrack.trackID,
						audio: this.microphoneTrack._kind,
						trackIDAudio: this.microphoneTrack.trackID,
					})
			}catch (e){
				console.error(e.message)
			}
		},
		destoryDeviceStream(deviceId) {
			const player = document.getElementById('WebMediaPlayer_'+deviceId)
			const mediaContainer = document.getElementById('media-container')
			//if (mediaContainer) {
			//	mediaContainer.removeChild(player)
			//}
			if(player){
				player.parentNode.removeChild(player)
				this.reservePlayDiv('WebMediaPlayer_'+deviceId)
			}
			const index = this.playIdList.indexOf(deviceId)
			this.playList.splice(index,1)
			this.playIdList.splice(index,1)
			this.playTitleList.splice(index,1)
			this.voiceChatUrlList.splice(index,1)		
		},
		reservePlayDiv(trackID){
			const tempId = this.getCellByTrackId(trackID)
			if(tempId) {
				this.cellCountArr[tempId].data = null
				this.cellCountArr[tempId].isShow = false
				this.cellCountArr[tempId].title = 'player' +String(tempId+1)
				this.cellCountArr[tempId].userId = null
				this.cellCountArrBak[tempId].data = null
				this.cellCountArrBak[tempId].isShow = false
				this.cellCountShow[tempId] = false
				this.cellCountArrBak[tempId].title = 'player' +String(tempId+1)
				this.cellCountArrBak[tempId].userId = null
				this.cellCountTitle[tempId] = 'player' +String(tempId+1)
			}
		},
		playerCallbackFunc(cbType, cbParams){
			if (cbType == 'playbackTime') {
				console.log("当前回放时间: " + cbParams)
			} else if (cbType == 'ended') {
				console.log("播放结束")
			}
			console.log("Callback " + cbType + ":  " + cbParams);
		},
		findFirstMissId(){
			const expectedMax = this.cellCountArr.length
			const expectedRange = Array.from({ length: expectedMax }, (_, i) => i + 1);
			const set = new Set(this.playIdList)
			return expectedRange.filter(num => !set.has(num))
		},
		playDeviceStream(){
			//console.log('-playList-')
			//console.log(this.playList)
			this.playList.forEach((item, index) =>{
				//const tmpCount = this.playCount
				//const tmpCount = this.findFirstMissId()[0] - 1
				//console.log('-playDeviceStream-'+tmpCount)
				let cellId = this.getEmptyCell()
				if(typeof cellId === 'undefined') {
					return
				}
				//const parentDiv = document.getElementById('cell' + tmpCount)
				const parentDiv = document.getElementById(this.cellCountArr[cellId].id)
				console.log(parentDiv)
				const div = document.createElement('div')
				div.id = 'WebMediaPlayer_' + this.playIdList[index]
				div.style = 'width: 100%; height:auto;object-fit: fill;position: absolute;top: 28%;'
				if(this.checkTrackIdInPlay(div.id)) return
				if(parentDiv) {
					parentDiv.appendChild(div)
					const player = new WebMediaPlayer(item, div.id, this.playerCallbackFunc,{
						cbUserPtr: this,
						decodeType: 'auto',
						openAudio: false,
						BigPlay: true,
					})
					player.play('',1,0)
					console.log('-getCellCountArr-'+cellId)
					this.cellCountArr[cellId].isShow = true
					this.cellCountArr[cellId].title = this.playTitleList[index]
					this.cellCountArr[cellId].data = div.id
					this.cellCountArrBak[cellId].isShow = true
					this.cellCountShow[cellId] = true
					this.cellCountArrBak[cellId].title = this.playTitleList[index]
					this.cellCountArrBak[cellId].data = div.id
					this.cellCountTitle[cellId] = this.playTitleList[index]
					this.currentTitleIndex = cellId
				}
				if(this.playCount){
					this.playCount++
				}
				//this.playDivList.push(tmpCount)
			})
		},
		getMicrophonesAndCameras(){ 
			navigator.mediaDevices.getUserMedia({video: true})
			this.microphones = QNRTC.getMicrophones();
			this.cameras = QNRTC.getCameras();
		},
		async getMessageToken() {
			const base_url = this.prefixUrl
			//const base_url = 'http://localhost:9090'
			const res = await this.$axios.post(`${base_url}/room/Roomagettoken?userid=${this.messageid}123&roomnum=${this.messageroomname}message&role=user`)
			if (res.data.code == '200') {
				this.messageToken = res.data.data;
				// console.log("-token-");
				// console.log(this.roomToken)
			}		
		},
		async getRoomToken(){
			const base_url = this.prefixUrl
			//const base_url = 'http://localhost:9090'
			const res = await this.$axios.post(`${base_url}/room/Roomagettoken?userid=${this.userid}&roomnum=${this.roomname}&role=user`)
			if (res.data.code == '200') {
				this.roomToken = res.data.data;
				// console.log("-token-");
				// console.log(this.roomToken)
			}
		},
		async listDevice(){
			const base_url = this.prefixUrl
			const res = await this.$axios.get(`${base_url}/device/get_all_online_devices?namespaceid=xuxiao-gb-https`);
			this.deviceOnlineListItem  = JSON.parse(res.data.data.info.split('\n')[2]).items;
		},
		async findNnameByCname(nname){
			const base_url = this.prefixUrl
			const res = await this.$axios.get(`${base_url}/room/find_cname?user_ename=${nname}`);
			return res;
		},
		async fetchUsername(userID){
			console.log('userID'+userID)
			if(userID) {
				const regex = /Z(.*?)Z/
				const match = userID.match(regex)
				const clientName = await this.findNnameByCname(match[1])
				if(clientName) {
					const username = clientName.data.data
					return username
				}
			} else {
				const localUser = this.userid
				console.log('localUser'+localUser)
				const regex = /Z(.*?)Z/
				const match = localUser.match(regex)
				const clientName = await this.findNnameByCname(match[1])
				if(clientName) {
					const username = clientName.data.data
					return username
				}
			}			
		},
		async playUserTracks(tracks, mirrorTrack) {
			// document.getElementById('undefined').remove()
			// await client.unpublish(this.cameraTrack)
			const mediaContainer = document.getElementById('media-container');
			for (const track of tracks){
				if (track.isAudio()) {
					track.play(document.body).catch((e) => {})
				} else{
					//console.log('-this.playCount-'+this.playCount)
					//const tmpCount = this.playCount
					//const tmpCount = this.findFirstMissId()[0] - 1
					//console.log('-playUserTracks-'+tmpCount)
					console.log("###track###")
					console.log(track)
					let username = await this.fetchUsername(track.userID)
					console.log(username)
					let cellId = this.getEmptyCell()
					console.log('-cellId-')
					console.log(cellId)
      				if(typeof cellId === 'undefined') {
						return
					}
					//const parentDiv = document.getElementById('cell' + tmpCount)
					const parentDiv = document.getElementById(this.cellCountArr[cellId].id)
					console.log(parentDiv)
					const div = document.createElement('div');
					div.id = track.trackID;
					div.style = 'width:100%; height:auto;object-fit: fill; position: absolute;top: 28%;'
					if(this.checkTrackIdInPlay(div.id)) return
					console.log('-after this.checkTrackIdInPlay-')
					if(username) {
						console.log('-getCellCountArr-'+cellId)
						console.log(username)
						this.cellCountArr[cellId].isShow = true
						this.cellCountArr[cellId].title = username
						this.cellCountArr[cellId].data = track.trackID
						this.cellCountArr[cellId].userId = track.userID
						this.cellCountArrBak[cellId].isShow = true
						this.cellCountShow[cellId] = true
						this.cellCountArrBak[cellId].title = username
						this.cellCountArrBak[cellId].data = track.trackID
						this.cellCountArrBak[cellId].userId = track.userID
						this.cellCountTitle[cellId] = username
						console.log(this.cellCountArr)
					}
					if(parentDiv) {
						parentDiv.appendChild(div)
						this.cellCountArr[cellId].isShow = true
						this.cellCountArr[cellId].data = track.trackID
						this.cellCountArr[cellId].userId = track.userID
						this.cellCountShow[cellId] = true
						this.cellCountArrBak[cellId].isShow = true
						this.cellCountArrBak[cellId].data = track.trackID
						this.cellCountArrBak[cellId].userId = track.userID
					}
					try{
						if(mirrorTrack!==null) {
							track.play(div,{mirror: mirrorTrack})
						} else {
							track.play(div,{mirror: true})
						}
					}catch(e){
						console.log(e.message)
					}
				}
			}
		},
		leave() {
			this.client
				.leave()
				.then(() => {
					if (!this.cameraTrack) this.cameraTrack.destroy()
					this.microphoneTrack.destroy()
				})
				.catch((e) => {
					console.log(e.message)
				})
			this.$router.go(-1)
		},
		makeAudioContextRunning(){
			// console.log("---makeAudioContextRunning---")
			if (!this.audioContext||this.audioContext.state === 'closed') {
				this.audioContext = new AudioContext({ sampleRate: 8000 })
			}
			if (this.audioContext.state === 'suspended') {
				this.audioContext.resume()
			}
		},
		async getMediaStream(){
			/*if(!navigator.mediaDevices) {
				console.log("您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )")
				return Promise.reject('您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )')			
			}
			return navigator.mediaDevices.getUserMedia({ audio: true })
				.catch(() => Promise.reject('请确保录音设备正常，并允许浏览器获取录音权限，否则无法使用对讲功能'))*/
			if (navigator.mediaDevices) {
				//最新的标准API
				return navigator.mediaDevices.getUserMedia({
							'audio':{ echoCancellation: false },
							'video':{ 'facingMode': "user" }//调用前置摄像头,后置摄像头使用video: { facingMode: { exact: "environment" } }
						})
						.then(success)
						.catch(error)
			} else if (navigator.webkitGetUserMedia) {
				//webkit核心浏览器
				return navigator.webkitGetUserMedia(constraints, success, error)
			} else if (navigator.mozGetUserMedia) {
				//firfox浏览器
				return navigator.mozGetUserMedia(constraints, success, error);
			} else if (navigator.getUserMedia) {
				//旧版API
				return navigator.getUserMedia(constraints, success, error);
			} else {
				console.log("您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )")
				return Promise.reject('您的浏览器不支持获取用户设备，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )')	
			}	
		},
		async onAudioProcess(event){
			const channelData = event.inputBuffer.getChannelData(0)
			this.dataList.push(channelData.slice())
			if (this.dataList.length >= this.radio.value) {
				this.sendAudioData()
				this.dataList = []
			}	  
		},
		async startRecord(){
			this.mediaStream = await this.getMediaStream();
			await this.makeAudioContextRunning()
			try{
				this.audioSourceNode = this.audioContext.createMediaStreamSource(mediaStream)
				this.processorNode = this.audioContext.createScriptProcessor(4096, 1, 1)
				this.audioSourceNode.connect(this.processorNode)
				this.processorNode.onaudioprocess = this.onAudioProcess
				this.processorNode.connect(this.audioContext.destination)
			} catch(e) {
				throw new Error('您的浏览器音频相关接口异常，无法使用对讲功能，建议使用 Chrome 浏览器 ( 版本 >= 74 )')
			}
		},
		async stopRecord() {
			if (this.dataList.length) {
				this.sendAudioData(this.dataList)
				this.dataList = []
			}
		},
		async sendAudioData(){
			const base_url = this.prefixUrl
			//const base_url = 'http://localhost:9090'
			const base64Audio = reSampleAndEncodeAudioData(this.dataList, 4096, this.radio.value)
			this.voiceChatUrlList.forEach(async(item,index)=>{
				try{
					await axios.post(`${base_url}/device/sendVoiceChat`,qs.stringify({url: `${item.audioSendAddrForHttp}`,base64_pcm: `${base64Audio}`}));
				//await this.SendVoiceChat(item.audioSendAddrForHttp,base64Audio)
				} catch (error) {
				let resUrl = await axios.get(`${base_url}/device/getVoiceChatUrl?namespaceid=xuxiao-gb-https&streamid=${this.playIdList[index]}`);
				//let resUrl = await this.GetVoiceChatUrl(this.playIdList[index])
				let sendUrl = JSON.parse(resUrl.data.data.info.split('\n')[2])
				if (sendUrl != null && sendUrl.audioSendAddrForHttp != "")
				{
					this.voiceChatUrlList.splice(index,1,sendUrl)
					//await this.SendVoiceChat(sendUrl.audioSendAddrForHttp,base64Audio)
				}
				}
			})
		},
		async createDeviceStream(deviceId, title) {
			const base_url = this.prefixUrl
			//const base_url = 'http://localhost:9090'
			let res = await axios.get(`${base_url}/device/getstreamurl?namespaceid=xuxiao-gb-https&streamid=${deviceId}`);
			let streamUrl = JSON.parse(res.data.data.info.split('\n')[2]);
			this.playList.push(streamUrl.playUrls.flv);
			//this.playList.push(streamUrl.playUrls.rtmp);
			this.playIdList.push(deviceId);
			if(title) {
    			this.playTitleList.push(title)
			} else {
				console.log('-createDeviceStream-')
				console.log(this.deviceOnlineListItem)
				for(let i=0;i<this.deviceOnlineListItem.length;i++) {
					if(this.deviceOnlineListItem[i].deviceId === deviceId) {
						console.log(this.deviceOnlineListItem[i])
						this.playTitleList.push(this.deviceOnlineListItem[i].name)
						break
					}
				}
			}
			try{
				let resUrl = await axios.get(`${base_url}/device/getVoiceChatUrl?namespaceid=xuxiao-gb-https&streamid=${deviceId}`);
				let sendUrl = JSON.parse(resUrl.data.data.info.split('\n')[2])
				if (sendUrl != null && sendUrl.audioSendAddrForHttp != "") {
				voiceChatUrlList.push(sendUrl)
				console.log('获得语音对讲地址')
				startRecord()
				} else {
				console.log('获得语音对讲地址失败')
				}
			} catch(e) {
				console.log('createDeviceStream',e)
			}
			this.playDeviceStream()
		}
    }
	}
</script>

<style scoped>
.context_whole {
	border-radius: 5px;
	height: 100%;
	.media-container {
		display: flex;
	}
}
.float{
	position: fixed;
	right: 5%;
	bottom: 10%;
	text-align: center;
	width:20%;
	height:40%;
    border:none;
	z-index:9999;
	background-color: transparent;
}
.floatBtn{
	color:white;
	opacity: 0.8;
	width:80px;
	height:80px;
	padding: 20px;
	margin: 5px;
	border-radius: 50%;
	border: 1px solid #000;
}

.active_tab img {
	width: 26px;
	height: 26px;
}
.player {
	height: 71vh;
}
.title {
	position: absolute;
	bottom: 5px;
	left: 5px;
	z-index:188;
	background-color: black;
	color: white;
}
.cellBitrate {
  position: absolute; 
  bottom: 5px; 
  right: 5px; 
  z-index: 188;
}
.player-w4 {
	width: 50%;
	height: 55% !important;
	box-sizing: border-box;/**可有可无 */
	float:left;
	overflow: hidden;
	position: relative;
}

/* 自定义选中的颜色 */
.van-tabbar-item--active {
	color: #d81e06;
}

.bottom {
	z-index:200;
}


.tui {
	width: 50px;
	height: 50px;
	margin-top: -40px;
	z-index: 100000;
}

[class*=van-hairline]::after {
	border: none !important
}
</style>