<template>
  <div class="playerVideo" @contextmenu.prevent="openContextMenu"></div>
</template>

<script>
export default {
  name: 'playVideo',
  props: {
    str1: {
      type: String,
      default: '',
    },
  },
  create() {
    console.log('下标', this.str1)
  },
}
</script>

<style scoped>
.playerVideo {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  height: 100%;
  width: 100%;
  border: 1px solid white;
  color: white;
  text-align: center;
  object-fit: cover;
  position: relative;
}
</style>
